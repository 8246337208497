<template>
  <div id="article">
    <CommonTitleStyleAndSearch @change="inputClick"></CommonTitleStyleAndSearch>
    <div class="common_layout">
      <div class="layout_left">
        <!-- <Article></Article> -->
        
        <CommonArticleList :dataList="dataList" @articleType="articleType"></CommonArticleList>
        <CommonPagination
        :pageNum="pageNum"
        :pageSize="pageSize"
        :totalNum="totalNum"
        placement="right"></CommonPagination> 
      </div>
      <div class="layout_right">
        <CommonTypeList @change="changeClick"></CommonTypeList>
        <CommonHotArticleList></CommonHotArticleList>
        <template v-if="$route.meta.needType">
          <CommonContribute></CommonContribute>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTitleStyleAndSearch from '@/components/CommonTitleStyleAndSearch.vue'
import CommonPagination from '@/components/CommonPagination.vue'
import CommonTypeList from '@/components/CommonTypeList.vue'
import CommonContribute from '@/components/CommonContribute.vue'
import CommonArticleList from '@/components/CommonArticleList.vue'
import CommonHotArticleList from '@/components/CommonHotArticleList.vue'
import merge from 'webpack-merge'
// import Article from './components/Article.vue'
export default {
  components: {
    CommonTitleStyleAndSearch,
    // Article,
    CommonPagination,
    CommonTypeList,
    CommonContribute,
    CommonArticleList,
    CommonHotArticleList
  },
  data() {
    return {
      pageNum: parseInt(this.$route.query.pager) || 1,
      pageSize: 10,
      totalNum: 0,
      dataList: [],
      searchData: {
        classificationName: '',
        title: ''
      },
      search: false
    }
  },
  mounted() {
    this.showPage();
  },
  methods: {
    articleType(type) {
      this.searchData.articleClassificationId = type;
      this.pageNum = 1;
      this.showPage();
    },
    showPage() {
      if(this.search) return;
      else this.search = true
      this.$API.ArticleList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        articleClassificationId: this.searchData.articleClassificationId ||  this.$store.state.articleClassificationId,
        title: this.searchData.title,
        state: 1,
        parentClassification: this.$route.meta.index, //0-政策法规，1-标准研制，2-双碳示范
      }).then(res => {
        if(res.data.code == 200) {
          res.data.data.list.forEach(item => {
            item.traffic = 0;
            item.collection = 0;
          })
          this.dataList = res.data.data.list;
          this.totalNum = res.data.data.total; 
          this.showTotal();
          this.search = false;
          window.scrollTo(0, 0)
        }
      })
    },
    showTotal() {
      this.$API.ArticleTotalList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        articleClassificationId: this.searchData.articleClassificationId ||  this.$store.state.articleClassificationId,
        title: this.searchData.title,
        state: 1,
        parentClassification: this.$route.meta.index, //0-政策法规，1-标准研制，2-双碳示范
      }).then(res => {
        if(res.data.code == 200) {
          res.data.data.forEach((item, index) => {
           this.dataList[index].traffic = item.traffic;
           this.dataList[index].collection = item.collection;
          })
        }
      })
    },
    changeClick(val) {
      this.searchData.articleClassificationId = val.isTrusted ? '' : val;
      this.pageNum = 1;
      this.$router.replace({
        query: merge(this.$route.query, { pager: 1 }),
      });
      this.showPage()
    },
    inputClick(val) {
      if(this.search) return
      this.searchData.title = val.isTrusted ? '' : val;
      this.pageNum = 1;
      this.$router.replace({
        query: merge(this.$route.query, { pager: 1 }),
      });
      this.showPage()
    }
  }
}
</script>

<style lang='scss'>
  #article {
    .arco-empty {
      padding: 100px 0;
    }
  }
</style>