<template>
  <div class="common_title_search">
    <div class="title">
      <h3>
        <svg-icon :icon-class="$route.meta.icon || 'file'"></svg-icon>{{ $route.meta.title }}
      </h3>
    </div>
    <div class="search">
      <a-input-search 
        v-model="value" 
        allow-clear
        :style="{width:'260px'}" 
        :placeholder="$route.meta.place || '搜索标题'"
        @search="searchClick"
        @press-enter="searchClick"
        @clear="searchClick"
        @input="inputClick"/>
    </div>
  </div>
</template>

<script>
export default {
  props: ['clear', 'searchStatus'],
  data() {
    return {
      value: ''
    }
  },
  watch: {
    clear(n) {
      if(!n) this.value = null;
    },
    searchStatus(n) {
      if(n) this.$emit('change', this.value)
    }
  },
  methods: {
    searchClick() {
      if(!this.$store.state.loginIn) return this.$store.commit('open_type', 1)
      this.$emit('change', this.value)
    },
    inputClick(value) {
      if(!value) {
        this.$emit('change', value)
      }
    }
  }
}
</script>

<style lang='scss'>
  .common_title_search {
    width: 1200px;
    margin: 0 auto 10px;
    display: flex;


    .title {
      width: 900px;
      height: 60px;
      line-height: 46px;
      margin-right: 20px;
      flex-shrink: 0;
      padding: 7px 10px;

      .svg-icon {
        width: 32px;
        height: 32px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -4px;
      }

      h3 {
        font-size: 32px;
        color: #1D2129;
        font-weight: 600;

      }
    }

    .search {
      padding: 10px;

      .arco-input-wrapper {
        padding: 0 10px;
      }

      ::-webkit-input-placeholder{
        color: #C0C4CC;
      }
    }
  }
</style>