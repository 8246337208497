<template>
  <div class="Common_Hot_Article_List">
    <div class="top">
      <div><span></span> 热门文章</div>
    </div>
    <div class="article_list">
      <div class="article_item" v-for="(line, index) in ArticleList" :key="index" @click="eventClick(line)">
        <span :style="{
          background: index < 3 ? '#ff8139' : '#c0c4cc'
        }">
          <!-- <svg-icon v-if="index == 0" icon-class="top"></svg-icon> -->
          <!-- <template v-else>{{ index }}</template> -->
          {{ index + 1 }}
        </span>
        <p>{{ line.title }}</p>
      </div> 
    </div>
  </div>
</template>

<script>
import SvgIcon from './SvgIcon.vue'
export default {
  components: { SvgIcon },
  data() {
    return {
      ArticleList: []
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    showPage() {
      this.$API.HotArticleList().then(res => {
        if(res.data.code == 200) {
          this.ArticleList = res.data.data
        }
      })
    },
    eventClick(item) {
      if(item.parentClassification == 0) {
          window.open(`/policiesAndRegulationsDetail/${item.id}`)
        }else if(item.parentClassification == 1) {
          window.open(`/standardDevelopmentDetail/${item.id}`)
        }else {
          window.open(`/doubleCDemonstrationDetail/${item.id}`)
        }
    }
  }
}
</script>

<style lang='scss'>
  .Common_Hot_Article_List {
    padding: 10px 0;
    margin: 10px 0;

    .top {
      padding: 15px 10px;

      & > div {
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        display: flex;
        align-items: center;

        & > span {
          width: 8px;
          height: 20px;
          background: #05a081;
          border-radius: 2px;
          display: inline-block;
          margin-right: 15px;

        }
      }
    }

    .article_list {
      
      .article_item {
        padding: 11px;
        display: flex;
        line-height: 20px;
        cursor: pointer;

        &:hover {
          background: #FAFAFA;
        }

        & > span {
          display: flex;
          width: 20px;
          height: 20px;
          font-size: 12px;
          text-align: center;
          color: #ffffff;
          background: #FF8139;
          margin-right: 11px;
          flex-shrink: 0;
          border-radius: 2px;
          align-items: center;
          justify-content: center;

          .svg-icon {
            width: 14px;
            height: 14px;
          }
        }

        & > p {
          font-size: 14px;
          color: #303133;
          text-overflow: ellipsis;
          overflow:hidden;
          white-space: nowrap;
          width: 230px;
          flex-shrink: 0;
        }

      }
    }
  }
</style>