<template>
  <div id="CommonArticleList">
    <template v-if="dataList.length > 0">
      <div class="item" v-for="item in dataList" :key="item.id">
        <div class="item_left" @click="detailClick(item)"><img :src="baseUrl + item.coverAddress" /></div>
        <div class="item_right">
          <h3>
            <a-button 
              type="outline" 
              class="pointer"
              @click="typeClick(item)">{{ item.classificationName }}</a-button>
            <span @click="detailClick(item)">{{ item.title }}</span>
          </h3>
          <p class="pointer" @click="detailClick(item)" v-html="item.summary"></p>
          <p class="clearfix">
            <span>{{ item.sourceName }}</span>
            <span v-if="!disabled" class="pull-right">
              {{ item.traffic }}<span style="margin: 0 5px;">阅读</span>
              <span v-if="item.collection">
                <span style="margin-right: 5px;">·</span>{{ item.collection }}<span style="margin: 0 10px 0 5px;">收藏</span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </template>
    <a-empty v-else />
  </div>
</template>

<script>
export default {
  props: ['dataList', 'disabled'],
  methods: {
    detailClick(item) {
      if(this.$route.meta.detail) {
        window.open(`${this.$route.meta.detail}/${item.id}`)
      }else {
        if(item.parentClassification == 0) {
          window.open(`/policiesAndRegulationsDetail/${item.id}`)
        }else if(item.parentClassification == 1) {
          window.open(`/standardDevelopmentDetail/${item.id}`)
        }else {
          window.open(`/doubleCDemonstrationDetail/${item.id}`)
        }
      }
    },
    typeClick(item) {
      
      if(this.$route.name == 'home'){
        this.$store.commit('article_type', item.articleClassificationId)
        if(item.parentClassification == 0) {
          this.$router.push(`/policiesAndRegulations`)
        }else if(item.parentClassification == 1) {
          this.$router.push(`/standardDevelopment`)
        }else {
          this.$router.push(`/doubleCDemonstration`)
        }
      }else{
        this.$emit('articleType', item.articleClassificationId);
        this.$store.commit('article_type', item.articleClassificationId)
      }
      
    }
  }
}
</script>

<style lang='scss'>
  #CommonArticleList {
    width: 900px;

    .item {
      padding: 30px 10px;  
      display: flex;

      &:hover {
        background: #fafafa;
      }

      .item_left {
        width: 240px;
        height: 160px;
        margin-right: 20px;
        flex-shrink: 0;
        overflow: hidden;
        border-radius: 4px;

        & > img {
          width: 100%;
          height: 100%;
          cursor: pointer;
          transition: all 0.2s;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .item_right {
        width: calc(100% - 260px);
        flex-shrink: 0;

        .arco-btn-outline {
          padding: 2px 11px;
          font-size: 12px;
          margin-right: 10px;
          height: auto;
          line-height: 18px;
          margin-bottom: 6px;
          border-radius: 4px;
        }

        h3 {
          font-size: 18px;
          color: #1d2129;
          line-height: 26px;
          margin-bottom: 15px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;

          & > span {
            cursor: pointer;

            &:hover {
              color: #05A081;
            }
          }
        }

        p {
          width: 100%;
          font-size: 14px;
          color: #86909c;
          line-height: 24px;
          margin-bottom: 18px; 
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;

          & > span {
            color: #4e5969;
            margin-right: 25px;
          }

          &:last-child {
            margin-bottom: 0;
            line-height: 20px;
          }

          .pull-right {
            font-size: 12px;
            color: #86909c;
            line-height: 20px;
            margin-right: 0;
          }
        }
      }
    }
  }
</style>