<template>
  <div class="common_contribute">
    <div class="top">
      <div><span></span> {{ data[$route.meta.needType].title }}</div>
    </div>
    <p>{{ data[$route.meta.needType].content }}</p>
    <a-button type="primary" @click="eventClick(data[$route.meta.needType].url)">{{ data[$route.meta.needType].buttonName }}</a-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        standardDevelopment: {
          title: '标准研制信息投稿',
          content: '广东省碳达峰碳中和标准化技术信息公共服务平台是全国首个双碳标准信息平台。是您发布标准研制信息获得人气最专业的双碳信息平台之一。',
          url: '/tougao',
          buttonName: '我要投稿'
        },
        doubleCDemonstration: {
          title: '双碳技术示范案例投稿',
          content: '广东省碳达峰碳中和标准化技术信息公共服务平台是全国首个双碳标准信息平台。是您发布双碳相关技术示范案例获得人气最专业的双碳信息平台之一。',
          url: '/tougao',
          buttonName: '我要投稿'
        },
        trainingCourses: {
          title: '标准培训信息投稿',
          content: '广东省碳达峰碳中和标准化技术信息公共服务平台是全国首个双碳标准信息平台。是您发布标准培训获得人气最专业的双碳信息平台之一。',
          url: 'https://tgik6tfkz3.jiandaoyun.com/f/623a7bc775e96d000821b93c',
          buttonName: '我要发布培训课程'
        }
      }
    }
  },
  methods: {
    eventClick(url) {
      if(!this.$store.state.loginIn && this.$route.meta.needType == 'trainingCourses') return this.$store.commit('open_type', 1)
      window.open(url)
    }
  }
  
}
</script>

<style lang='scss'>
.common_contribute {
  margin: 20px 0;
  padding: 10px 0;

  .top {
    padding: 15px 10px;

    & > div {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      display: flex;
      align-items: center;

      & > span {
        width: 8px;
        height: 20px;
        background: #05a081;
        border-radius: 2px;
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
  & > p{
    padding: 0 10px;
    font-size: 14px;
    color: #86909c;
    line-height: 24px;
    margin: 5px 0 20px;
  }

  .arco-btn-primary {
    width: 260px;
    height: 50px;
    line-height: 50px;
    margin: 0 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
  }
}
</style>