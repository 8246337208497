<template>
  <div class="common_type_list">
    <div class="top clearfix">
      <div class="pull-left"><span></span> 分类</div>
      <div class="pull-right" v-if="active">
        <a-link @click="clearClick" href="javascript:void(0);"><svg-icon icon-class="clean"></svg-icon>取消筛选</a-link>
      </div>
    </div>
    <div class="list">
      <div :class="[{'active': active == item.classificationName}, 'item clearfix']" v-for="item in typeList" :key="item.title" @click="chooseClick(item)">
        <p class="pull-left">{{ item.classificationName }}</p>
        <p class="pull-right">{{ item.upNumber || 0 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import merge from 'webpack-merge'
export default {
  data() {
    return {
      // 通过$route.meta.typeApi去获取列表即可
      typeList: [],
      active: null
    }
  },
  mounted() {
    this.showPage();
  },
  watch: {
    '$store.state.articleClassificationId': {
      handler(n, o) {
        if(n && n != o) {
          this.active = this.typeList.find(item => item.id == n).classificationName
        }
      }
    }
  },
  methods: {
    showPage() {
      this.$API.ArticleTypeList({
        parentClassification: this.$route.meta.index
      }, 3).then(res => {
        if(res.data.code == 200) {
          this.typeList = res.data.data;
          if( this.$store.state.articleClassificationId) this.active = res.data.data.find(item => item.id == this.$store.state.articleClassificationId).classificationName
        }
      })
    },
    chooseClick(item) {
      this.active = item.classificationName;
      this.$store.commit('article_type', '')
      this.$emit('change', item.id)
    },
    clearClick() {
      this.active = null;
      this.$store.commit('article_type', '')
      this.$emit('change', '')
    }
  }
  
}
</script>

<style lang='scss'>
  .common_type_list {
    .top {
      padding: 12px 0 12px 10px;

      & > div {
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        display: flex;
        align-items: center;

        & > span {
          width: 8px;
          height: 20px;
          background: #05a081;
          border-radius: 2px;
          display: inline-block;
          margin-right: 15px;
        }
      }

      .arco-link {
        width: 94px;
        height: 32px;
        border-radius: 4px;
        font-size: 14px;
        color: #606266;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal;

        .svg-icon {
          width: 18px;
          height: 18px;
          margin-right: 5px;
          color: #606266;
        }
      }
    }

    .list {
      .item {
        padding: 10px;
        font-size: 14px;
        color: #1d2129;
        border-radius: 2px;
        line-height: 20px;

        &:hover {
          background: #fafafa;
          cursor: pointer;
        }
      }

      .active {
        background: #f2faf9;
        color: #05A081;
        font-weight: bold;
      }
    }
  }
</style>